import React from 'react'
import { WrapperContainer, WrapperSection } from '../../wrappers'
import { T } from '../../typo'
import { Grid } from '@material-ui/core'
import { s, colors, alpha, globals } from '../../style'

const BannerInfo = ({ data, theme }) => {
  const { slug_section, title, description, subtitle, link_external } = data
  return (
    <WrapperSection
      noPaddingTop
      css={{ [s.sm_down]: { paddingTop: 0 } }}
      slug_section={slug_section}>
      <WrapperContainer theme={theme} extraCss={sSection(theme)}>
        <Grid container css={{ borderRadius: 'inherit' }}>
          <Grid item xs={12} sm={7} md={8} css={sTextContainer}>
            <T d={32} m={24} sm={16} mb={0.75} upper bold>
              {title}
            </T>
            <T xs={14} sm={14} o={0.6}>
              {description}
            </T>
          </Grid>
          <Grid item xs={12} sm={5} md={4} css={sButtonContainer(theme)}>
            <a
              href={link_external}
              className="buttonCall"
              target="_blank"
              rel="noreferrer noopenner">
              {subtitle}
            </a>
          </Grid>
        </Grid>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sSection = (theme) => ({
  background: `linear-gradient(45deg, ${colors['light'].brand}, ${colors[
    theme
  ].main.concat(alpha[30])})`,
  display: 'flex',
  flexDirection: 'row',
  color: 'white',
  padding: '0px !important',
  [s.xs]: {
    flexDirection: 'column',
  },
})

const sTextContainer = {
  [s.xs]: {
    padding: globals.spacing.inside.mobile,
  },
  [s.sm_only]: {
    padding: globals.spacing.inside.mobile * 2,
  },
  [s.md]: {
    padding: globals.spacing.inside.desktop,
  },
}

const sButtonContainer = (theme) => ({
  borderRadius: 'inherit',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [s.xs]: {
    padding: '3rem',
  },

  [s.sm]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  '.buttonCall': {
    transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
    padding: '17px 44px',
    background: `linear-gradient(45deg, ${colors[theme].main}, ${colors[theme].mainDark})`,
    fontSize: '1.5rem',
    [s.sm_only]: {
      fontSize: '1rem',
    },
    fontWeight: 'bold',
    borderRadius: '2rem',
  },

  '&:hover': {
    '.buttonCall': {
      transform: 'scale(1.25) translateY(-10px)',
      boxShadow: `0px 16px 32px ${colors[theme].main.concat(alpha[16])}`,
    },
  },
})

export default BannerInfo
